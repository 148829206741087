import { checkInstallPlugin } from 'src/utils/common';
import { StateCreator } from 'zustand';

interface PluginState {
  pluginInstallModelVisible: boolean;
}

export type PluginStateStore = PluginState & {
  /**
   * 设置是否显示安装插件提示框
   */
  setPluginInstallModelVisible: (visible: boolean) => void;
  /**
   * 检测是否安装了插件，没有则弹出安装插件提示框
   */
  checkHasInstallPlugin: () => boolean;
};

export const pluginStateStore: StateCreator<PluginStateStore> = (set) => ({
  pluginInstallModelVisible: false,
  setPluginInstallModelVisible: (visible) => set(() => ({ pluginInstallModelVisible: visible })),
  checkHasInstallPlugin: () => {
    if (checkInstallPlugin()) {
      return true;
    }
    set({
      pluginInstallModelVisible: true,
    });
    return false;
  },
});
