/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React from 'react';
import { Card, Layout, List, Stepper, Table, Button, Text, Modal } from 'tea-component';
import { ExternalLink } from '../../common/common';
import { useFetchOneGenCert } from 'src/common/apis/certificates/hooks';
import { useHistory } from 'react-router-dom';
const { mergeable } = Table.addons;

const { Content } = Layout;

function InvokeContractTable() {
  return (
    <Table
      bordered="all"
      className={'table-m'}
      columns={[
        {
          key: 'type',
          header: '合约类型',
        },
        {
          key: 'method',
          header: '方法名',
        },
        {
          key: 'param',
          header: '参数',
        },
        {
          key: 'desc',
          header: '说明',
        },
      ]}
      records={[
        {
          type: 'Rust/TinyGo',
          method: 'save',
          param: 'file_hash,file_name,time',
          desc: '文件哈希存证',
        },
        {
          type: 'Rust/TinyGo',
          method: 'find_by_file_hash',
          param: 'file_hash',
          desc: '通过文件哈希查找存证信息',
        },
        {
          type: 'Docker-go',
          method: 'save',
          param: 'file_hash,file_name,time',
          desc: '',
        },
        {
          type: 'Docker-go',
          method: 'findByFileHash',
          param: 'file_hash',
          desc: '',
        },
        {
          type: 'Solidity',
          method: '见abi文件',
          param: '',
          desc: '',
        },
      ]}
      addons={[
        mergeable({
          rowSpan: (columnIndex, recordIndex) => {
            if (columnIndex === 0) {
              if (recordIndex === 0 || recordIndex === 2) {
                return 2;
              }
              if (recordIndex === 1 || recordIndex === 3) {
                return 0;
              }
              return 1;
            }
            return 1;
          },
        }),
      ]}
    />
  );
}

export default function GuidePage() {
  const history = useHistory();
  const { fetchOneGenCert } = useFetchOneGenCert();
  // 快速生成证书
  async function oneGenerateCert() {
    const result = await fetchOneGenCert();
    if (result === 'OK') {
      const { destroy } = Modal.show({
        onClose: () => destroy(),
        children: (
          <>
            <Modal.Body>证书已经生成完成，您可以到链账户管理-证书账户模块上查看证书详情。</Modal.Body>
            <Modal.Footer>
              <Button
                type="primary"
                onClick={() => {
                  history.push({
                    pathname: '/certificates',
                  });
                  destroy();
                }}
              >
                去查看
              </Button>
              <Button onClick={() => destroy()}>关闭</Button>
            </Modal.Footer>
          </>
        ),
      });
    }
  }
  const steps = [
    {
      id: 'gen-cert',
      label: '生成证书文件',
      detail: (
        <>
          <div>
            <Text theme="text">
              先在链账户管理-证书管理模块，申请部署长安链所需要的组织证书，节点证书和用户证书。如您对证书的命名无特殊要求，点击快速生成证书，将自动帮您生成
              <br />
              四个组织证书、四个节点证书、四个用户证书。
            </Text>
          </div>
          <Button className="tea-mt-2n" type="primary" onClick={oneGenerateCert}>
            快速生成证书
          </Button>
        </>
      ),
    },
    {
      id: 'gen-chain',
      label: '生成链配置文件',
      detail: <>再利用长安链的可装配性，在新增区块链模块勾选想要的配置，装配出对应的链配置文件。</>,
    },
    {
      id: 'start-chain',
      label: '启动链',
      detail: <>将配置文件下载到本地，然后将文件移动到在Linux环境下解压，并执行解压后的，start.sh脚本即可启动链。</>,
    },
    {
      id: 'subscribe-chain',
      label: '订阅链',
      detail: <>链启动完成后，可将之订阅到管理台上进行管理。</>,
    },
    {
      id: 'deploy-contract',
      label: '部署示例合约',
      detail: (
        <>
          链订阅成功后，可在合约管理处部署官方提供的各语言的示例合约，如需自行开发合约可参考
          <ExternalLink
            href={
              'https://docs.chainmaker.org.cn/v2.3.2/html/instructions/%E6%99%BA%E8%83%BD%E5%90%88%E7%BA%A6%E5%BC%80%E5%8F%91.html'
            }
          >
            官方文档智能合约开发
          </ExternalLink>
          章节。
          <List type={'bullet'} className={'content-mt-2n'}>
            <List.Item>
              Rust：
              <ExternalLink
                href={
                  'https://git.chainmaker.org.cn/chainmaker/chainmaker-go/-/raw/v2.2.0/test/wasm/rust-fact-2.0.0.wasm'
                }
              >
                rust-fact-2.0.0.wasm
              </ExternalLink>
            </List.Item>
            <List.Item>
              Solidity：
              <ExternalLink href={'https://docs.chainmaker.org.cn/images/token.zip'}>evm-token.zip</ExternalLink>
              【zip中包含的是 1)上链的合约文件：token.bin， 2)客户端使用的接口文件：token.abi，请自行解压。】
            </List.Item>
            <List.Item>
              TinyGO：
              <ExternalLink
                href={
                  'https://git.chainmaker.org.cn/chainmaker/chainmaker-go/-/raw/v2.1.0/test/wasm/go-fact-2.0.0.wasm'
                }
              >
                go-fact-2.0.0.wasm
              </ExternalLink>
            </List.Item>
            <List.Item>
              DockerGo：
              <ExternalLink
                href={
                  'https://git.chainmaker.org.cn/chainmaker/chainmaker-go/-/raw/v2.3.0/tools/cmc/testdata/claim-docker-go-demo/docker-fact.7z'
                }
              >
                contract_fact.7z
              </ExternalLink>
            </List.Item>
          </List>
        </>
      ),
    },
    {
      id: 'invoke-contract',
      label: '调用示例合约',
      detail: (
        <>
          <div className={'tea-mb-2n'}>示例合约的调用方法如下，可在上链管理处进行调用。</div>
          <InvokeContractTable />
        </>
      ),
    },
    {
      id: 'finish',
      label: '在区块链浏览器上查看交易信息',
      detail: <>每次跟链交互产生交易时，都可在区块链浏览器上查询相关信息。</>,
    },
  ];
  return (
    <div>
      <Content.Body full>
        <Card>
          <Card.Body className={'content-pl-8n'}>
            <h1 className={'content-mb-4n guide-title'}>长安链-快速使用引导</h1>
            <Stepper type="process-vertical" steps={steps} className={'guide-content'} />
          </Card.Body>
        </Card>
      </Content.Body>
    </div>
  );
}
