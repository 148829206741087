/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import { Layout, List, Menu, NavMenu } from 'tea-component';
import routes from './routes';
import { Link, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import LoginPage from './routes/login';
import { UserResponse } from './common/interface';
import { ModifyPasswordModal } from './routes/accounts/modals/modify-password-modal';
import ErrorBoundary from './common/components/error-boundary';
import { Icon } from 'tea-component/es';
import { LocalSetting } from './common/local-setting';
import { fetchLogout } from './common/apis/accounts';
import { useGotoClick } from './utils/hooks';
import PluginDownloadModel from './common/components/plugin-download-model';

const { Body, Content, Sider } = Layout;
const { Header } = Layout;

export const myWorker = new Worker('./worker', { type: 'module' });

interface IMenuItem {
  title: string;
  icon: string;
  hoverIcon: string;
  href: string;
  subMenu?: Array<{
    href: string;
    title: string;
  }>;
}

const menus: Array<IMenuItem> = [
  {
    title: '快速引导',
    icon: '/static/icon/menu_guide.svg',
    hoverIcon: '/static/icon/menu_guide_hover.svg',
    href: '/guide',
  },
  {
    title: '链账户管理',
    icon: '/static/icon/menu_certificate.svg',
    hoverIcon: '/static/icon/menu_certificate_hover.svg',
    href: '',
    subMenu: [
      {
        href: '/certificates',
        title: '证书账户(pwc)',
      },
      {
        href: '/public',
        title: '公钥账户(pk)',
      },
    ],
  },
  {
    title: '区块链管理',
    icon: '/static/icon/menu_chain.svg',
    hoverIcon: '/static/icon/menu_chain_hover.svg',
    href: '/chains',
  },
  {
    title: '平台账号管理',
    icon: '/static/icon/menu_account.svg',
    hoverIcon: '/static/icon/menu_account_hover.svg',
    href: '/accounts',
  },
  {
    title: '日志监控',
    icon: '/static/icon/menu_logs.svg',
    hoverIcon: '/static/icon/menu_logs_hover.svg',
    href: '/logs',
  },
  {
    title: '生态工具',
    icon: '/static/icon/menu_sdk.svg',
    hoverIcon: '/static/icon/menu_sdk_hover.svg',
    href: '/sdk',
  },
  {
    title: '问题反馈',
    icon: '/static/icon/menu_faq.svg',
    hoverIcon: '/static/icon/menu_faq_hover.svg',
    href: '/faq',
  },
];

const MenuWithRouter = withRouter(({ location }) => {
  const pathname = location?.pathname;
  return (
    <Menu theme="dark" title="长安链管理平台" collapsable>
      {menus.map((item) => {
        let selected;
        if (item.subMenu) {
          selected =
            item.subMenu.filter((subitem) => Boolean(pathname.match(new RegExp(`^${subitem.href}`)))).length > 0;
        } else {
          selected = Boolean(pathname.match(new RegExp(`^${item.href}`)));
        }
        return item.subMenu ? (
          <Menu.SubMenu
            className="sub-menu-custom"
            key={item.title}
            title={item.title}
            icon={selected ? item.hoverIcon : item.icon}
          >
            {item.subMenu.map((val) => (
              <Menu.Item
                key={val.title}
                title={val.title}
                selected={Boolean(pathname.match(new RegExp(`^${val.href}`)))}
                render={(children) => <Link to={val.href}>{children}</Link>}
              />
            ))}
          </Menu.SubMenu>
        ) : (
          <Menu.Item
            key={item.title}
            title={item.title}
            icon={selected ? item.hoverIcon : item.icon}
            selected={selected}
            render={(children) => <Link to={item.href}>{children}</Link>}
          />
        );
      })}
      <div className={'menu-tip'}>
        <Icon type={'info'} className={'tea-mr-1n'} />{' '}
        {`支持创建${CHAIN_MAKER.sdkVersion}版本底链，
订阅${CHAIN_MAKER.subscribeMinSdkVersion}+版本底链`}
      </div>
    </Menu>
  );
});

export const AppContext = React.createContext<{
  user: UserResponse | null;
}>({ user: null });

function App() {
  const [user, setUser] = useState<UserResponse | null>(null);
  const history = useHistory();
  const modifyPasswordRef = useRef<any>(null);
  const handleLogout = useCallback(async () => {
    await fetchLogout();
    LocalSetting.logout();
    window.location.href = '/login';
  }, [user]);

  useEffect(() => {
    if (LocalSetting.getToken()) {
      const user = LocalSetting.getUser();
      setUser(user);
    } else {
      history.push({
        pathname: '/login',
      });
    }
  }, []);

  const handleModifyPasswordClick = useCallback(() => {
    modifyPasswordRef.current.showModal();
  }, [modifyPasswordRef]);

  const goClick = useGotoClick('/certificates');

  if (!user) {
    return (
      <Route
        exact
        path="/login"
        render={() => (
          <LoginPage
            onSuccess={(user) => {
              setUser(user);
              goClick();
            }}
          />
        )}
        key="login"
      />
    );
  }
  return (
    <AppContext.Provider value={{ user }}>
      <Layout>
        <Header>
          <NavMenu
            left={
              <Link to="/">
                <img src="/static/icon/logo.svg" alt="logo" />
              </Link>
            }
            right={
              <NavMenu.Item
                type="dropdown"
                overlay={() => (
                  <List type="option">
                    <List.Item onClick={handleModifyPasswordClick}>修改密码</List.Item>
                    <List.Item onClick={handleLogout}>退出</List.Item>
                  </List>
                )}
              >
                {user.UserName}
              </NavMenu.Item>
            }
          />
        </Header>
        <Body className="main-content">
          <Sider>
            <MenuWithRouter />
          </Sider>
          <Content>
            <ErrorBoundary>
              <Switch>{routes}</Switch>
              <ModifyPasswordModal ref={modifyPasswordRef} />
            </ErrorBoundary>
          </Content>
        </Body>
      </Layout>
      <PluginDownloadModel />
    </AppContext.Provider>
  );
}

export default App;
