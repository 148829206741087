/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback } from 'react';
import { Button, Card, Col, Layout, Row } from 'tea-component';

const { Content } = Layout;
const sdkItems: {
  icon: string;
  title: string;
  version: string;
  docUrl: string;
  sdkUrl?: string;
}[] = [
  {
    icon: '/static/icon/logo2.svg',
    title: 'SmartIDE',
    version: 'Docker_go智能合约源码在线编写工具',
    docUrl: `https://ide.chainmaker.org.cn/login`,
  },
  {
    icon: '/static/icon/logo2.svg',
    title: '浏览器',
    version: '独立部署区块链浏览器',
    docUrl: `https://docs.chainmaker.org.cn/dev/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%B5%8F%E8%A7%88%E5%99%A8.html`,
  },
  {
    icon: '/static/icon/logo2.svg',
    title: 'CMC控制台',
    version: '长安链命令行交互式控制台',
    docUrl: `https://docs.chainmaker.org.cn/dev/%E5%91%BD%E4%BB%A4%E8%A1%8C%E5%B7%A5%E5%85%B7.html`,
  },
  {
    icon: '/static/icon/logo2.svg',
    title: 'SmartPlugin',
    version: '去中心化的Web浏览器签名插件',
    docUrl: `https://docs.chainmaker.org.cn/dev/%E9%95%BF%E5%AE%89%E9%93%BEWeb3%E6%8F%92%E4%BB%B6.html`,
  },
  {
    icon: '/static/icon/logo2.svg',
    title: 'CA证书服务',
    version: '提供证书签发，管理，更新，吊销等服务。',
    docUrl: `https://docs.chainmaker.org.cn/dev/CA%E8%AF%81%E4%B9%A6%E6%9C%8D%E5%8A%A1.html`,
  },
  {
    icon: '/static/icon/sdk/go.svg',
    title: 'Go SDK',
    version: '版本为1.15或以上',
    docUrl: `https://docs.chainmaker.org.cn/${CHAIN_MAKER.sdkVersion}/html/sdk/GoSDK使用说明.html`,
    sdkUrl: 'https://git.chainmaker.org.cn/chainmaker/chainmaker-sdk-go.git',
  },
  {
    icon: '/static/icon/sdk/java.svg',
    title: 'Java SDK',
    version: 'jdk 1.8.0_202或以下',
    docUrl: `https://docs.chainmaker.org.cn/${CHAIN_MAKER.sdkVersion}/html/sdk/JavaSDK使用说明.html`,
    sdkUrl: 'https://git.chainmaker.org.cn/chainmaker/chainmaker-sdk-java.git',
  },
  {
    icon: '/static/icon/sdk/nodejs.svg',
    title: 'NodeJS SDK',
    version: 'nodejs 14.0.0+',
    docUrl: `https://docs.chainmaker.org.cn/${CHAIN_MAKER.sdkVersion}/html/sdk/NodejsSDK使用说明.html`,
    sdkUrl: 'https://git.chainmaker.org.cn/chainmaker/chainmaker-sdk-nodejs.git',
  },
];
export default function SDKPage() {
  const gotoExternal = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);
  return (
    <div>
      <div className={'_cm-sdk-banner'}>
        <div className={'_cm-sdk-banner__img'}>
          <img src="/static/icon/sdk_background.svg" alt="" />
        </div>
        <div className="_cm-sdk-banner__introduce">
          <div className="_cm-sdk-banner__introduce-tit">长安链·ChainMaker</div>
          <div className="_cm-sdk-banner__introduce-desc">
            长安链作为区块链开源底层软件平台，包涵区块链核心框架、丰富的组件库和工具集，致力于为用户高效、精准地解决差异化区块链实现需求，构建高性能、高可信、高安全的新型数字基础设施，同时也是国内首个自主可控区块链软硬件技术体系。
          </div>
          <div className="_cm-sdk-banner__btns">
            <button onClick={() => gotoExternal('https://git.chainmaker.org.cn/chainmaker/chainmaker-go.git')}>
              源码下载
            </button>
            <button
              onClick={() => gotoExternal(`https://docs.chainmaker.org.cn/${CHAIN_MAKER.sdkVersion}/html/index.html`)}
            >
              开源文档
            </button>
          </div>
        </div>
      </div>
      <Content.Body full>
        <Card>
          <Card.Body>
            <Row>
              {sdkItems.map((item) => (
                <Col span={8} key={item.title}>
                  <div className={'_cm-sdk-list'}>
                    <div className={'_cm-sdk-list-bd'}>
                      <img className={item.icon} src={item.icon} />
                      <div className={'list-text'}>
                        <p className={'list-text__tit'}>{item.title}</p>
                        <p className={'list-text__version'}>{item.version}</p>
                      </div>
                    </div>
                    <div className={'_cm-sdk-list-fd'}>
                      <Button className={'_cm-sdk-list-fd__btn'} onClick={() => gotoExternal(item.docUrl)}>
                        前往使用
                      </Button>
                      {item.sdkUrl && (
                        <Button className={'_cm-sdk-list-fd__btn'} onClick={() => gotoExternal(item!.sdkUrl || '')}>
                          下载源码
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Content.Body>
    </div>
  );
}
